import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PAGES = gql`
  query pagesAdmin($filter: PagesAdminFilter, $sort: PagesSort) {
    pagesAdmin(filter: $filter, sort: $sort) {
      count
      pages {
        id
        slug
        title
        description
        status
        type
        isDefault
        allowDelete
        createdBy {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PAGE = gql`
  query pageAdmin($where: PageUniqueInput!) {
    pageAdmin(where: $where) {
      id
      slug
      title
      description
      status
      type
      isDefault
      metaHeader
      metaFooter
      createdBy {
        id
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_MODULE_TYPES = gql`
  query pageModuleTypes {
    pageModuleTypes {
      category
      key
      name
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissions {
      count
      permissions {
        key
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TOPIC = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_CONTRIBUTORS = gql`
  query contributorsAdmin(
    $filter: ContributorsFilter
    $where: ContributorsWhereInput
  ) {
    contributorsAdmin(filter: $filter, where: $where) {
      count
      contributors {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter) {
    collectionsAdmin(filter: $filter) {
      count
      collections {
        id
        title
        description
      }
    }
  }
`;

export const GET_AUTO_GENERATED_BY_COMMUNITIES = gql`
  query communitiesAdmin($filter: CommunitiesFilter) {
    communitiesAdmin(filter: $filter) {
      count
      communities {
        id
        title
        description
      }
    }
  }
`;

export const GET_AUTO_GENERATED_BY_SOURCE = gql`
  query sources($where: SourcesWhereInput, $filter: SourcesFilter) {
    sourcesAdmin(where: $where, filter: $filter) {
      sources {
        id
        name
      }
      count
    }
  }
`;

const video = `
    video {
      id
      title
      videoThumbnail {
        url
      }
    }
`;
const videos = `
  videos {
    order
    id
    title
    videoThumbnail {
      url
    }
  }
`;

const getVideoConfig = (configKey, key, isMultiVideo = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiVideo ? videos : video}
    autoGenerate
    autoGenerateByType
    videoAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT = gql`
  fragment videoContinueWatchingCarouselData on VideoContinueWatchingCarouselData {
    settings {
      title
      description
      viewAll
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
    }
  }
`;
const VIDEO_WATCH_LATER_CAROUSEL_FRAGMENT = gql`
  fragment videoWatchLaterCarouselData on VideoWatchLaterCarouselData {
    settings {
      title
      description
      viewAll
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
    }
  }
`;

const VIDEO_CAROUSEL_CONFIG_FRAGMENT = gql(
  getVideoConfig('carouselConfig', 'VideoCarouselModuleConfigData')
);
const VIDEO_CAROUSEL_FRAGMENT = gql`
  ${VIDEO_CAROUSEL_CONFIG_FRAGMENT}
  fragment videoCarouselData on VideoCarouselData {
    settings {
      description
      viewAll
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
    }
    config {
      ...carouselConfig
      autoGenerateLimit
    }
  }
`;

const VIDEO_LIST_CONFIG_FRAGMENT = gql(
  getVideoConfig('listConfig', 'VideoListModuleConfigData')
);
const VIDEO_LIST_FRAGMENT = gql`
  ${VIDEO_LIST_CONFIG_FRAGMENT}
  fragment videoListFragment on VideoListData {
    settings {
      description
      separator
      searchFilter
      tagsFilter
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
      videoDescription
      viewAll
    }
    config {
      ...listConfig
      autoGenerateLimit
    }
  }
`;

const VIDEO_GRID_CONFIG_FRAGMENT = gql(
  getVideoConfig('gridConfig', 'VideoGridModuleConfigData')
);
const VIDEO_GRID_FRAGMENT = gql`
  ${VIDEO_GRID_CONFIG_FRAGMENT}
  fragment videoGridFragment on VideoGridData {
    settings {
      title
      description
      searchFilter
      tagsFilter
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
      viewAll
    }
    config {
      ...gridConfig
      autoGenerateLimit
    }
  }
`;
const FEATURED_VIDEO_CONFIG_FRAGMENT = gql(
  getVideoConfig('featureVideoConfig', 'FeaturedVideoModuleConfigData', false)
);
const FEATURED_VIDEO_FRAGMENT = gql`
  ${FEATURED_VIDEO_CONFIG_FRAGMENT}
  fragment featuredVideoFragment on FeaturedVideoData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoCTA
      collectionNames
    }
    config {
      ...featureVideoConfig
    }
  }
`;

const FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT = gql(
  getVideoConfig(
    'featureVideoSliderConfig',
    'FeaturedVideoSliderModuleConfigData'
  )
);
const FEATURED_VIDEO_SLIDER_FRAGMENT = gql`
  ${FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT}
  fragment featuredVideoSlicerFragment on FeaturedVideoSliderData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      carouselIndicator
      videoCTA
      collectionNames
    }
    config {
      ...featureVideoSliderConfig
      autoGenerateLimit
    }
  }
`;

const VIDEO_PLAYER_FRAGMENT = gql`
  fragment videoPlayerFragment on VideoPlayerData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
    }
    config {
      video {
        id
        title
        videoThumbnail {
          url
        }
      }
    }
  }
`;

const VIDEO_PLAYER_V2_FRAGMENT = gql`
  fragment videoPlayerV2Fragment on VideoPlayerDataV2 {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoTag
      videoOrder
    }
    config {
      video {
        id
        title
        videoThumbnail {
          url
        }
      }
    }
  }
`;

const podcast = `
  id
  title
  imageThumbnail {
    url
  }
`;

const featurePodcast = `
  podcast {
    order
    id
    title
    imageThumbnail {
      url
    }
  }
`;

const podcasts = `
  podcasts {
    order
    ${podcast}
  }
`;

const getPodcastConfig = (configKey, key, isMultiPodcast = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiPodcast ? podcasts : podcast}
    autoGenerate
    autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateLimit
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const getFeaturePodcastConfig = (configKey, key) => `
  fragment ${configKey} on ${key} {
    ${featurePodcast}
    autoGenerate
    autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const PODCAST_CONTINUE_LISTENING_CAROUSEL_FRAGMENT = gql`
  fragment podcastContinueListeningCarouselData on PodcastContinueListeningCarouselData {
    settings {
      title
      description
      viewAll
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      podcastOrder
    }
  }
`;

const PODCAST_LISTEN_LATER_CAROUSEL_FRAGMENT = gql`
  fragment podcastListenLaterCarouselData on PodcastListenLaterCarouselData {
    settings {
      title
      description
      viewAll
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      podcastOrder
    }
  }
`;

const PODCAST_CAROUSEL_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastCarouselConfig', 'PodcastCarouselModuleConfigData')
);
const PODCAST_CAROUSEL_FRAGMENT = gql`
  ${PODCAST_CAROUSEL_CONFIG_FRAGMENT}
  fragment podcastCarouselData on PodcastCarouselData {
    settings {
      title
      description
      viewAll
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      podcastOrder
      imageThumbnail
      podcastProgressBar
    }
    config {
      ...podcastCarouselConfig
    }
  }
`;

const PODCAST_LIST_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastListConfig', 'PodcastListModuleConfigData')
);
const PODCAST_LIST_FRAGMENT = gql`
  ${PODCAST_LIST_CONFIG_FRAGMENT}
  fragment podcastListFragment on PodcastListData {
    settings {
      description
      separator
      searchFilter
      tagsFilter
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      podcastOrder
      imageThumbnail
      podcastProgressBar
      podcastDescription
      viewAll
    }
    config {
      ...podcastListConfig
    }
  }
`;

const PODCAST_GRID_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastGridConfig', 'PodcastGridModuleConfigData')
);
const PODCAST_GRID_FRAGMENT = gql`
  ${PODCAST_GRID_CONFIG_FRAGMENT}
  fragment podcastGridFragment on PodcastGridData {
    settings {
      description
      searchFilter
      tagsFilter
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      podcastOrder
      imageThumbnail
      podcastProgressBar
      viewAll
    }
    config {
      ...podcastGridConfig
    }
  }
`;

const FEATURED_PODCAST_CONFIG_FRAGMENT = gql(
  getFeaturePodcastConfig(
    'featurePodcastConfig',
    'FeaturedPodcastModuleConfigData',
    false
  )
);

const FEATURED_PODCAST_FRAGMENT = gql`
  ${FEATURED_PODCAST_CONFIG_FRAGMENT}
  fragment featuredPodcastFragment on FeaturedPodcastData {
    settings {
      podcastDescription
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      podcastCTA
      imageThumbnail
      collectionNames
    }
    config {
      ...featurePodcastConfig
    }
  }
`;

const FEATURED_PODCAST_SLIDER_CONFIG_FRAGMENT = gql(
  getPodcastConfig(
    'featurePodcastSliderConfig',
    'FeaturedPodcastSliderModuleConfigData'
  )
);

const FEATURED_PODCAST_SLIDER_FRAGMENT = gql`
  ${FEATURED_PODCAST_SLIDER_CONFIG_FRAGMENT}
  fragment featuredPodcastSliderFragment on FeaturedPodcastSliderData {
    settings {
      podcastDescription
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastTag
      carouselIndicator
      podcastCTA
      imageThumbnail
      collectionNames
    }
    config {
      ...featurePodcastSliderConfig
    }
  }
`;

const PODCAST_PLAYER_FRAGMENT = gql`
  fragment podcastPlayerFragment on PodcastPlayerData {
    settings {
      podcastSpeaker
      podcastDate
      podcastDescription
      podcastOrder
      podcastTag
      podcastTimestamp
      podcastTopic
    }
    config {
      podcast {
        id
        title
        imageThumbnail {
          url
        }
      }
    }
  }
`;

const collection = `
  collection {
    id
    title
    thumbnail {
      url
    }
  }
`;
const collections = `
  collections {
    order
    id
    title
    thumbnail {
      url
    }
  }
`;
const getCollectionConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    aspectRatio
    collectionAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_CAROUSEL_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionCarouselConfigFragment',
    'CollectionCarouselModuleConfigData'
  )
);

const COLLECTION_CAROUSEL_FRAGMENT = gql`
  ${COLLECTION_CAROUSEL_CONFIG_FRAGMENT}
  fragment collectionCarouselFragment on CollectionCarouselData {
    settings {
      title
      description
      viewAll
      numberOfItems
    }
    config {
      ...collectionCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_GRID_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionGridConfigFragment',
    'CollectionGridModuleConfigData'
  )
);

const COLLECTION_GRID_FRAGMENT = gql`
  ${COLLECTION_GRID_CONFIG_FRAGMENT}
  fragment collectionGridFragment on CollectionGridData {
    settings {
      title
      description
      searchBar
      numberOfItems
      viewAll
      collectionDescription
    }
    config {
      ...collectionGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_LIST_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionListConfigFragment',
    'CollectionListModuleConfigData'
  )
);
const COLLECTION_LIST_FRAGMENT = gql`
  ${COLLECTION_LIST_CONFIG_FRAGMENT}
  fragment collectionListFragment on CollectionListData {
    settings {
      title
      description
      separator
      collectionDescription
      numberOfItems
      viewAll
    }
    config {
      ...collectionListConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_COLLECTION_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionConfigFragment',
    'FeaturedCollectionModuleConfigData',
    false
  )
);
const FEATURED_COLLECTION_FRAGMENT = gql`
  ${FEATURED_COLLECTION_CONFIG_FRAGMENT}
  fragment featuredCollectionFragment on FeaturedCollectionData {
    settings {
      title
      description
      numberOfItems
      collectionDescription
    }
    config {
      ...featuredCollectionConfigFragment
    }
  }
`;

const FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionSliderConfigFragment',
    'FeaturedCollectionSliderModuleConfigData'
  )
);
const FEATURED_COLLECTION_SLIDER_FRAGMENT = gql`
  ${FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT}
  fragment featuredCollectionSliderFragment on FeaturedCollectionSliderData {
    settings {
      collectionTitle
      collectionDescription
      collectionImage
    }
    config {
      ...featuredCollectionSliderConfigFragment
      autoGenerateLimit
    }
  }
`;

const contributor = `
  contributor {
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const contributors = `
  contributors {
    order
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const getContributorConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    contributorAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? contributors : contributor}
  }
`;

const CONTRIBUTOR_CAROUSEL_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorCarouselConfigFragment',
    'ContributorCarouselModuleConfigData'
  )
);
const CONTRIBUTOR_CAROUSEL_FRAGMENT = gql`
  ${CONTRIBUTOR_CAROUSEL_CONFIG_FRAGMENT}
  fragment contributorCarouselFragment on ContributorCarouselData {
    settings {
      description
      viewAll
      numberOfVideos
      numberOfPodcasts
    }
    config {
      ...contributorCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const CONTRIBUTOR_LIST_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorListConfigFragment',
    'ContributorListModuleConfigData'
  )
);

const CONTRIBUTOR_LIST_FRAGMENT = gql`
  ${CONTRIBUTOR_LIST_CONFIG_FRAGMENT}
  fragment contributorListFragment on ContributorListData {
    settings {
      description
      separator
      contributorDescription
      numberOfVideos
      viewAll
      numberOfPodcasts
    }
    config {
      ...contributorListConfigFragment
      autoGenerateLimit
    }
  }
`;

const CONTRIBUTOR_GRID_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorGridConfigFragment',
    'ContributorGridModuleConfigData'
  )
);
const CONTRIBUTOR_GRID_FRAGMENT = gql`
  ${CONTRIBUTOR_GRID_CONFIG_FRAGMENT}
  fragment contributorGridFragment on ContributorGridData {
    settings {
      description
      numberOfVideos
      viewAll
      numberOfPodcasts
    }
    config {
      ...contributorGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_CONTRIBUTOR_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'featuredContributorGridConfigFragment',
    'FeaturedContributorModuleConfigData',
    false
  )
);
const FEATURED_CONTRIBUTOR_FRAGMENT = gql`
  ${FEATURED_CONTRIBUTOR_CONFIG_FRAGMENT}
  fragment featuredContributorGridFragment on FeaturedContributorData {
    settings {
      contributorName
      numberOfVideos
      contributorImage
      numberOfPodcasts
    }
    config {
      ...featuredContributorGridConfigFragment
    }
  }
`;

const tag = `
  tag {
    id
    name
  }
`;
const tags = `
  tags {
    order
    id
    name
  }
`;
const getTagConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    tagAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? tags : tag}
  }
`;

const TAG_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCarouselConfigFragment', 'TagCarouselModuleConfigData')
);
const TAG_CAROUSEL_FRAGMENT = gql`
  ${TAG_CAROUSEL_CONFIG_FRAGMENT}
  fragment tagCarouselFragment on TagCarouselData {
    settings {
      title
      description
      viewAll
    }
    config {
      ...tagCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const TAG_CLOUD_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCloudConfigFragment', 'TagCloudModuleConfigData')
);
const TAG_CLOUD_FRAGMENT = gql`
  ${TAG_CLOUD_CONFIG_FRAGMENT}
  fragment tagCloudFragment on TagCloudData {
    settings {
      title
      description
      viewAll
    }
    config {
      ...tagCloudConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_TAG_CONFIG_FRAGMENT = gql(
  getTagConfig(
    'featuredTagConfigFragment',
    'FeaturedTagModuleConfigData',
    false
  )
);
const FEATURED_TAG_FRAGMENT = gql`
  ${FEATURED_TAG_CONFIG_FRAGMENT}
  fragment featuredTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      ...featuredTagConfigFragment
    }
  }
`;

const topic = `
  topic {
    id
    name
  }
`;
const topics = `
  topics {
    order
    id
    name
  }
`;
const getTopicsConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    topicAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? topics : topic}
  }
`;

const TOPIC_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'topicCarouselConfigFragment',
    'TopicCarouselModuleConfigData'
  )
);
const TOPIC_CAROUSEL_FRAGMENT = gql`
  ${TOPIC_CAROUSEL_CONFIG_FRAGMENT}
  fragment topicCarouselFragment on TopicCarouselData {
    settings {
      description
      viewAll
      topicTitle
    }
    config {
      ...topicCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const TOPIC_LIST_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicListConfigFragment', 'TopicListModuleConfigData')
);
const TOPIC_LIST_FRAGMENT = gql`
  ${TOPIC_LIST_CONFIG_FRAGMENT}
  fragment topicListFragment on TopicListData {
    settings {
      topicTitle
      viewAll
    }
    config {
      ...topicListConfigFragment
      autoGenerateLimit
    }
  }
`;

const TOPIC_GRID_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicGridConfigFragment', 'TopicGridModuleConfigData')
);
const TOPIC_GRID_FRAGMENT = gql`
  ${TOPIC_GRID_CONFIG_FRAGMENT}
  fragment topicGridFragment on TopicGridData {
    settings {
      description
      topicTitle
      viewAll
    }
    config {
      ...topicGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_TOPIC_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'featuredTopicConfigFragment',
    'FeaturedTopicModuleConfigData',
    false
  )
);
const FEATURED_TOPIC_FRAGMENT = gql`
  ${FEATURED_TOPIC_CONFIG_FRAGMENT}
  fragment featuredTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    config {
      ...featuredTopicConfigFragment
    }
  }
`;

const CTA_BLOCK_FRAGMENT = gql`
  fragment ctaBlockFragment on CTABlockData {
    settings {
      image
      description
      secondaryAction
      eventDetails
    }
    config {
      asset {
        id
        url
      }
      theme
      eventTime
      eventDate
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_WITH_TEXT_FRAGMENT = gql`
  fragment ctaBannerWithTextFragment on CTABannerWithTextData {
    settings {
      title
      image
      description
    }
    config {
      asset {
        id
        url
      }
      theme
      primaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const HERO_FRAGMENT = gql`
  fragment heroFragment on HeroData {
    settings {
      description
      primaryAction
      secondaryAction
      textFields
    }
    config {
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
      eventTime
      eventDate
      eventLocation
      alignment
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const INFORMATION_FRAGMENT = gql`
  fragment informationFragment on InformationData {
    settings {
      description
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_FRAGMENT = gql`
  fragment ctaBannerFragment on CTABannerData {
    config {
      asset {
        id
        url
      }
    }
  }
`;

const DONATION_FRAGMENT = gql`
  fragment donationFragment on DonationData {
    settings {
      description
      secondaryTitleAndDescription
      title
      coverTransactionFee
      newsLetterOptIn
    }
    config {
      key
      theme
      secondaryTitle
      secondaryDescription
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
    }
  }
`;

const COPY_HEAVY_FRAGMENT = gql`
  fragment copyHeavyFragment on CopyHeavyData {
    settings {
      title
      date
      contributor
      textFields
      primaryAction
      secondaryAction
    }
    config {
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const COPY_HEAVY_HERO_FRAGMENT = gql`
  fragment copyHeavyHeroFragment on CopyHeavyHeroData {
    settings {
      title
      date
      contributor
      textFields
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const QUOTE_FRAGMENT = gql`
  fragment quoteFragment on QuoteData {
    settings {
      indicators
      authorName
      authorSubTitle
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const LEAD_GEN_FRAGMENT = gql`
  fragment leadGenFragment on LeadGenData {
    settings {
      title
      description
      icon
      image
    }
    config {
      key
      theme
      completionTitle
      completionDescription
      asset {
        id
        url
      }
      iconAsset {
        id
        url
      }
    }
  }
`;
const CONTACT_US_FRAGMENT = gql`
  fragment contactUsFragment on ContactUsData {
    settings {
      title
      description
    }
    config {
      key
    }
  }
`;

const FAQS_FRAGMENT = gql`
  fragment faqsFragment on FAQData {
    settings {
      title
      description
    }
    config {
      questions {
        question
        answer
      }
    }
  }
`;
const SPACER_FRAGMENT = gql`
  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
`;

const CTA_BUTTON_LIST_FRAGMENT = gql`
  fragment CTAButtonListDataFragment on CTAButtonListData {
    config {
      items {
        asset {
          id
          url
          title
        }
        buttonType
        internalPageType
        title
        type
        url
      }
    }
    settings {
      bottomSeparator
    }
  }
`;

const CTA_CAROUSEL_FRAGMENT = gql`
  fragment CTACarouselDataFragment on CTACarouselData {
    config {
      items {
        asset {
          id
          title
          url
        }
        description
        internalPageType
        title
        type
        url
      }
      ctaCarouselType: type
    }
    settings {
      title
      description
      itemImage
      itemTitle
    }
  }
`;

const CTA_GRID_FRAGMENT = gql`
  fragment CTAGridDataFragment on CTAGridData {
    config {
      items {
        asset {
          id
          title
          url
        }
        description
        internalPageType
        title
        type
        url
      }
      ctaGridType: type
    }
    settings {
      title
      description
      itemImage
      itemTitle
    }
  }
`;

const FORM_CAROUSEL_FRAGMENT = gql`
  fragment formCarouselData on FormCarouselData {
    settings {
      description
      formTitle
      formPrayer
      formShare
      title
    }
    config {
      autoGenerate
      formAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
      }
      formAutoGenerateSortBy: autoGenerateSortBy
      autoGenerateLimit
      type
      formLimit
      sortBy
      forms {
        id
        title
      }
    }
  }
`;
const SOURCE_CAROUSEL_FRAGMENT = gql`
  fragment sourceCarouselFragment on SourceCarouselData {
    settings {
      description
      numberOfArticles
      numberOfPodcasts
      numberOfVideos
      sourceDescription
      sourceImage
      title
      viewAll
    }
    config {
      autoGenerate
      autoGenerateLimit
      autoGenerateSortBy
      sources {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;

const SOURCE_LIST_FRAGMENT = gql`
  fragment sourceListFragment on SourceListData {
    settings {
      description
      numberOfArticles
      separator
      sourceDescription
      sourceImage
      title
      viewAll
    }
    config {
      autoGenerate
      autoGenerateLimit
      autoGenerateSortBy
      sources {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;

const ARTICLE_LIST_FRAGMENT = gql`
  fragment ArticleListDataFragment on ArticleListData {
    settings {
      description
      articleTimestamp
      articleAuthor
      articleTopic
      articleShare
      articlePrayer
      articleComment
      articleSave
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }
`;

const ARTICLE_LIST_V2_FRAGMENT = gql`
  fragment ArticleListDataV2Fragment on ArticleListDataV2 {
    settings {
      description
      articleTimestamp
      articleAuthor
      articleTopic
      articleShare
      articlePrayer
      articleComment
      articleSave
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }
`;

const ARTICLE_CAROUSEL_FRAGMENT = gql`
  fragment ArticleCarouselDataFragment on ArticleCarouselData {
    settings {
      description
      articleTimestamp
      articleAuthor
      articleTopic
      articleShare
      articlePrayer
      articleComment
      articleSave
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }
`;

const ARTICLE_CAROUSEL_V2_FRAGMENT = gql`
  fragment ArticleCarouselDataV2Fragment on ArticleCarouselDataV2 {
    settings {
      description
      articleTimestamp
      articleAuthor
      articleTopic
      articleShare
      articlePrayer
      articleComment
      articleSave
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }
`;

const FORM_DISPLAY_FRAGMENT = gql`
  fragment formDisplayData on FormDisplayData {
    settings {
      formDescription
      formTitle
    }
    config {
      autoFillForm
      form {
        id
        title
      }
    }
  }
`;
const FEATURED_ARTICLE_FRAGMENT = gql`
  fragment FeaturedArticleDataFragment on FeaturedArticleData {
    settings {
      articleDate
      articleAuthor
      articleTopic
      relatedContent
      articleShare
      articleComment
      articlePrayer
      articleSave
      articleHtml
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      article {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }
`;

const FEATURED_ARTICLE_V2_FRAGMENT = gql`
  fragment FeaturedArticleDataV2Fragment on FeaturedArticleDataV2 {
    settings {
      articleDate
      articleAuthor
      articleTopic
      relatedContent
      articleShare
      articleComment
      articlePrayer
      articleSave
      articleHtml
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      article {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }
`;

const COMMUNITY_CAROUSEL_FRAGMENT = gql`
  fragment CommunityCarouselFragment on CommunityCarouselData {
    settings {
      title
      description
      communityDescription
      communityMemberCount
    }
    config {
      autoGenerate
      autoGenerateLimit
      communityAutoGenerateSortBy: autoGenerateSortBy
      autoGenerateByMember
      communities {
        id
        imageUrl
        title
      }
    }
  }
`;

const COMMUNITY_GRID_FRAGMENT = gql`
  fragment CommunityGridFragment on CommunityGridData {
    settings {
      communityMemberCount
      description
      locationSort
      sortByTag
      title
    }
    config {
      autoGenerate
      autoGenerateLimit
      communityAutoGenerateSortBy: autoGenerateSortBy
      autoGenerateByMember
      communities {
        id
        imageUrl
        title
      }
    }
  }
`;

const FEATURED_COMMUNITY_FRAGMENT = gql`
  fragment FeaturedCommunityFragment on FeaturedCommunityData {
    config {
      autoGenerate
      communityAutoGenerateSortBy: autoGenerateSortBy
      community {
        id
        imageUrl
        title
      }
    }
    settings {
      title
      description
      communityDescription
      communityMemberCount
    }
  }
`;

export const POST_CAROUSEL_FRAGMENT = gql`
  fragment PostCarouselFragment on PostCarouselData {
    config {
      autoGenerate
      postAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Community {
          id
          title
        }
      }
    }
    settings {
      description
      postComment
      postPray
      postShare
      title
      viewAll
    }
  }
`;

export const POST_LIST_FRAGMENT = gql`
  fragment PostListFragment on PostListData {
    config {
      autoGenerate
      postAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Community {
          id
          title
        }
      }
    }
    settings {
      description
      postComment
      postPray
      postShare
      title
      viewAll
    }
  }
`;

export const POST_DETAIL_FRAGMENT = gql`
  fragment PostDetailFragment on PostDetailData {
    config {
      post {
        id
        text
      }
    }
    settings {
      description
      title
    }
  }
`;

const COLLECTION_ITEM_CAROUSEL_FRAGMENT = gql`
  fragment CollectionItemCarouselFragment on CollectionItemCarouselData {
    settings {
      description
      title
      viewAll
    }
    config {
      autoGenerateLimit
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;
const COLLECTION_ITEM_GRID_FRAGMENT = gql`
  fragment CollectionItemGridFragment on CollectionItemGridData {
    settings {
      description
      title
      viewAll
    }
    config {
      autoGenerateLimit
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;
const COLLECTION_ITEM_LIST_FRAGMENT = gql`
  fragment CollectionItemListFragment on CollectionItemListData {
    settings {
      description
      title
      viewAll
    }
    config {
      autoGenerateLimit
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

export const PAGE_MODULE_DETAILS_FRAGMENT = gql`
  ${VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT}
  ${VIDEO_WATCH_LATER_CAROUSEL_FRAGMENT}
  ${VIDEO_CAROUSEL_FRAGMENT}
  ${VIDEO_LIST_FRAGMENT}
  ${VIDEO_GRID_FRAGMENT}
  ${FEATURED_VIDEO_FRAGMENT}
  ${FEATURED_VIDEO_SLIDER_FRAGMENT}
  ${VIDEO_PLAYER_FRAGMENT}
  ${VIDEO_PLAYER_V2_FRAGMENT}
  ${PODCAST_LISTEN_LATER_CAROUSEL_FRAGMENT}
  ${PODCAST_CONTINUE_LISTENING_CAROUSEL_FRAGMENT}
  ${PODCAST_CAROUSEL_FRAGMENT}
  ${PODCAST_LIST_FRAGMENT}
  ${PODCAST_GRID_FRAGMENT}
  ${FEATURED_PODCAST_FRAGMENT}
  ${FEATURED_PODCAST_SLIDER_FRAGMENT}
  ${PODCAST_PLAYER_FRAGMENT}
  ${COLLECTION_CAROUSEL_FRAGMENT}
  ${COLLECTION_GRID_FRAGMENT}
  ${COLLECTION_LIST_FRAGMENT}
  ${FEATURED_COLLECTION_FRAGMENT}
  ${FEATURED_COLLECTION_SLIDER_FRAGMENT}
  ${CONTRIBUTOR_CAROUSEL_FRAGMENT}
  ${CONTRIBUTOR_LIST_FRAGMENT}
  ${CONTRIBUTOR_GRID_FRAGMENT}
  ${FEATURED_CONTRIBUTOR_FRAGMENT}
  ${TAG_CAROUSEL_FRAGMENT}
  ${TAG_CLOUD_FRAGMENT}
  ${FEATURED_TAG_FRAGMENT}
  ${TOPIC_CAROUSEL_FRAGMENT}
  ${TOPIC_GRID_FRAGMENT}
  ${TOPIC_LIST_FRAGMENT}
  ${FEATURED_TOPIC_FRAGMENT}
  ${CTA_BLOCK_FRAGMENT}
  ${CTA_BANNER_WITH_TEXT_FRAGMENT}
  ${HERO_FRAGMENT}
  ${CTA_BANNER_FRAGMENT}
  ${DONATION_FRAGMENT}
  ${COPY_HEAVY_FRAGMENT}
  ${COPY_HEAVY_HERO_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${INFORMATION_FRAGMENT}
  ${LEAD_GEN_FRAGMENT}
  ${CONTACT_US_FRAGMENT}
  ${FAQS_FRAGMENT}
  ${SPACER_FRAGMENT}
  ${CTA_BUTTON_LIST_FRAGMENT}
  ${CTA_CAROUSEL_FRAGMENT}
  ${CTA_GRID_FRAGMENT}
  ${FORM_CAROUSEL_FRAGMENT}
  ${FORM_DISPLAY_FRAGMENT}
  ${SOURCE_CAROUSEL_FRAGMENT}
  ${SOURCE_LIST_FRAGMENT}
  ${ARTICLE_LIST_FRAGMENT}
  ${ARTICLE_LIST_V2_FRAGMENT}
  ${ARTICLE_CAROUSEL_FRAGMENT}
  ${ARTICLE_CAROUSEL_V2_FRAGMENT}
  ${FEATURED_ARTICLE_FRAGMENT}
  ${FEATURED_ARTICLE_V2_FRAGMENT}
  ${COMMUNITY_CAROUSEL_FRAGMENT}
  ${COMMUNITY_GRID_FRAGMENT}
  ${FEATURED_COMMUNITY_FRAGMENT}
  ${POST_CAROUSEL_FRAGMENT}
  ${POST_LIST_FRAGMENT}
  ${POST_DETAIL_FRAGMENT}
  ${COLLECTION_ITEM_CAROUSEL_FRAGMENT}
  ${COLLECTION_ITEM_GRID_FRAGMENT}
  ${COLLECTION_ITEM_LIST_FRAGMENT}
  fragment pageModuleDetails on PageModule {
    id
    title
    description
    status
    permissions
    type
    isDefaultModule
    moduleData {
      ... on VideoContinueWatchingCarouselData {
        ...videoContinueWatchingCarouselData
      }
      ... on VideoWatchLaterCarouselData {
        ...videoWatchLaterCarouselData
      }
      ... on VideoCarouselData {
        ...videoCarouselData
      }
      ... on VideoListData {
        ...videoListFragment
      }
      ... on VideoGridData {
        ...videoGridFragment
      }
      ... on FeaturedVideoData {
        ...featuredVideoFragment
      }
      ... on FeaturedVideoSliderData {
        ...featuredVideoSlicerFragment
      }
      ... on VideoPlayerData {
        ...videoPlayerFragment
      }
      ... on VideoPlayerDataV2 {
        ...videoPlayerV2Fragment
      }
      ... on PodcastListenLaterCarouselData {
        ...podcastListenLaterCarouselData
      }
      ... on PodcastContinueListeningCarouselData {
        ...podcastContinueListeningCarouselData
      }
      ... on PodcastCarouselData {
        ...podcastCarouselData
      }
      ... on PodcastListData {
        ...podcastListFragment
      }
      ... on PodcastGridData {
        ...podcastGridFragment
      }
      ... on FeaturedPodcastData {
        ...featuredPodcastFragment
      }
      ... on FeaturedPodcastSliderData {
        ...featuredPodcastSliderFragment
      }
      ... on PodcastPlayerData {
        ...podcastPlayerFragment
      }
      ... on CollectionCarouselData {
        ...collectionCarouselFragment
      }
      ... on CollectionListData {
        ...collectionListFragment
      }
      ... on CollectionGridData {
        ...collectionGridFragment
      }
      ... on FeaturedCollectionData {
        ...featuredCollectionFragment
      }
      ... on FeaturedCollectionSliderData {
        ...featuredCollectionSliderFragment
      }
      ... on ContributorCarouselData {
        ...contributorCarouselFragment
      }
      ... on ContributorListData {
        ...contributorListFragment
      }
      ... on ContributorGridData {
        ...contributorGridFragment
      }
      ... on FeaturedContributorData {
        ...featuredContributorGridFragment
      }
      ... on TagCarouselData {
        ...tagCarouselFragment
      }
      ... on TagCloudData {
        ...tagCloudFragment
      }
      ... on FeaturedTagData {
        ...featuredTagFragment
      }
      ... on TopicCarouselData {
        ...topicCarouselFragment
      }
      ... on TopicListData {
        ...topicListFragment
      }
      ... on TopicGridData {
        ...topicGridFragment
      }
      ... on FeaturedTopicData {
        ...featuredTopicFragment
      }
      ... on CTABlockData {
        ...ctaBlockFragment
      }
      ... on CTABannerWithTextData {
        ...ctaBannerWithTextFragment
      }
      ... on HeroData {
        ...heroFragment
      }
      ... on CTABannerData {
        ...ctaBannerFragment
      }
      ... on DonationData {
        ...donationFragment
      }
      ... on CopyHeavyData {
        ...copyHeavyFragment
      }
      ... on CopyHeavyHeroData {
        ...copyHeavyHeroFragment
      }
      ... on QuoteData {
        ...quoteFragment
      }
      ... on InformationData {
        ...informationFragment
      }
      ... on LeadGenData {
        ...leadGenFragment
      }
      ... on ContactUsData {
        ...contactUsFragment
      }
      ... on FAQData {
        ...faqsFragment
      }
      ... on SpacerData {
        ...spaceFragment
      }
      ... on CTAButtonListData {
        ...CTAButtonListDataFragment
      }
      ... on CTACarouselData {
        ...CTACarouselDataFragment
      }
      ... on CTAGridData {
        ...CTAGridDataFragment
      }
      ... on FormCarouselData {
        ...formCarouselData
      }
      ... on FormDisplayData {
        ...formDisplayData
      }
      ... on SourceCarouselData {
        ...sourceCarouselFragment
      }
      ... on SourceListData {
        ...sourceListFragment
      }
      ... on ArticleListData {
        ...ArticleListDataFragment
      }
      ... on ArticleListDataV2 {
        ...ArticleListDataV2Fragment
      }
      ... on ArticleCarouselData {
        ...ArticleCarouselDataFragment
      }
      ... on ArticleCarouselDataV2 {
        ...ArticleCarouselDataV2Fragment
      }
      ... on FeaturedArticleData {
        ...FeaturedArticleDataFragment
      }
      ... on FeaturedArticleDataV2 {
        ...FeaturedArticleDataV2Fragment
      }
      ... on CommunityCarouselData {
        ...CommunityCarouselFragment
      }
      ... on CommunityGridData {
        ...CommunityGridFragment
      }
      ... on FeaturedCommunityData {
        ...FeaturedCommunityFragment
      }
      ... on PostCarouselData {
        ...PostCarouselFragment
      }
      ... on PostListData {
        ...PostListFragment
      }
      ... on PostDetailData {
        ...PostDetailFragment
      }
      ... on CollectionItemCarouselData {
        ...CollectionItemCarouselFragment
      }
      ... on CollectionItemGridData {
        ...CollectionItemGridFragment
      }
      ... on CollectionItemListData {
        ...CollectionItemListFragment
      }
    }
  }
`;

export const GET_PAGE_DETAILS = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }) {
      slug
      title
      description
      metaHeader
      metaFooter
      permissions
      allowDelete
      isDefault
      type
      apps {
        id
        name
      }
      modules {
        ...pageModuleDetails
      }
    }
  }
`;

export const GET_PAGE_DETAILS_FOR_PREVIEW = gql`
  fragment AssetFragment on Asset {
    categoryKey
    url
    blurhash
    serviceVideoThumbnail
    serviceImageThumbnail
  }

  fragment ContributorFragment on Contributor {
    id
    firstName
    lastName
    subTitle
    description
    image {
      url
      blurhash
    }
    thumbnail {
      url
      blurhash
    }
    primaryColor
    videosCount
  }

  fragment TopicFragment on Topic {
    id
    key
    name
    primaryColor
  }

  fragment TagFragment on Tag {
    id
    name
  }

  fragment VideoFragment on Video {
    id
    title
    description
    topics {
      id
      name
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    tags {
      id
      name
    }
    createdAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
      blurhash
    }
  }

  fragment PodcastFragment on Podcast {
    id
    code
    contentRating
    createdAt
    description
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    image {
      url
      blurhash
    }
    imageThumbnail {
      url
      blurhash
    }
    metaHeader
    metaFooter
    permissions
    slug
    tags {
      name
      id
    }
    title
    topics {
      key
      name
      id
      primaryColor
    }
    audio {
      id
      url
      serviceImageThumbnail
      information {
        durationInSeconds
      }
    }
  }

  fragment CollectionFragment on Collection {
    id
    title
    itemsCount
    thumbnail {
      url
      blurhash
    }
  }

  fragment VideoContinueWatchingCarouselFragment on VideoContinueWatchingCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoWatchLaterCarouselFragment on VideoWatchLaterCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoCarouselFragment on VideoCarouselData {
    settings {
      viewAll
      videoProgressBar
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoListFragment on VideoListData {
    settings {
      videoProgressBar
      separator
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoGridFragment on VideoGridData {
    settings {
      videoProgressBar
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoSliderFragment on FeaturedVideoSliderData {
    settings {
      carouselIndicator
      videoCTA
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoFragment on FeaturedVideoData {
    settings {
      videoCTA
    }
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerFragment on VideoPlayerData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerV2Fragment on VideoPlayerDataV2 {
    config {
      video {
        ...VideoFragment
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }

  fragment PodcastListenLaterCarouselFragment on PodcastListenLaterCarouselData {
    settings {
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastContinueListeningCarouselFragment on PodcastContinueListeningCarouselData {
    settings {
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastCarouselFragment on PodcastCarouselData {
    settings {
      viewAll
      podcastProgressBar
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastListFragment on PodcastListData {
    settings {
      podcastProgressBar
      separator
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastGridFragment on PodcastGridData {
    settings {
      podcastProgressBar
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment FeaturedPodcastSliderFragment on FeaturedPodcastSliderData {
    settings {
      carouselIndicator
      podcastCTA
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment FeaturedPodcastFragment on FeaturedPodcastData {
    settings {
      podcastCTA
    }
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment PodcastPlayerFragment on PodcastPlayerData {
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment TopicCarouselFragment on TopicCarouselData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicListFragment on TopicListData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicGridFragment on TopicGridData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment FeaturedTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    featuredTopicConfig: config {
      autoGenerateSortBy
      autoGenerate
      topic {
        ...TopicFragment
      }
    }
  }

  fragment CollectionCarouselFragment on CollectionCarouselData {
    settings {
      viewAll
      numberOfItems
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionGridFragment on CollectionGridData {
    settings {
      numberOfItems
      viewAll
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionListFragment on CollectionListData {
    settings {
      separator
      numberOfItems
      viewAll
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionSliderFragment on FeaturedCollectionSliderData {
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionFragment on FeaturedCollectionData {
    settings {
      numberOfItems
    }
    config {
      collection {
        ...CollectionFragment
      }
    }
  }

  fragment ContributorCarouselFragment on ContributorCarouselData {
    settings {
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment ContributorGridFragment on ContributorGridData {
    settings {
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment ContributorListFragment on ContributorListData {
    settings {
      separator
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment FeaturedContributorFragment on FeaturedContributorData {
    config {
      contributor {
        ...ContributorFragment
      }
    }
  }

  fragment TagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment TagCloudFragment on TagCloudData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment FeaturedTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      autoGenerateSortBy
      autoGenerate
      tag {
        ...TagFragment
      }
    }
  }

  fragment CTABlockFragment on CTABlockData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      secondaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      theme
      eventTime
      eventDate
      eventLocation
    }
  }
  fragment CTABannerWithTextFragment on CTABannerWithTextData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }
      theme
    }
  }
  fragment HeroFragment on HeroData {
    config {
      asset {
        ...AssetFragment
      }
      eventTime
      eventDate
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      alignment
    }
  }

  fragment CTABannerFragment on CTABannerData {
    config {
      asset {
        ...AssetFragment
      }
    }
  }
  fragment DonationFragment on DonationData {
    config {
      theme
      secondaryTitle
      secondaryDescription
    }
  }
  fragment CopyHeavyFragment on CopyHeavyData {
    config {
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment CopyHeavyHeroFragment on CopyHeavyHeroData {
    config {
      asset {
        ...AssetFragment
      }
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment QuoteFragment on QuoteData {
    settings {
      indicators
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            ...AssetFragment
          }
        }
      }
    }
  }

  fragment informationFragment on InformationData {
    config {
      asset {
        url
        blurhash
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment leadGenFragment on LeadGenData {
    config {
      key
      theme
      asset {
        id
        url
      }
      iconAsset {
        id
        url
      }
    }
  }
  fragment contactUsFragment on ContactUsData {
    config {
      key
    }
  }

  fragment faqsFragment on FAQData {
    config {
      questions {
        question
        answer
      }
    }
  }

  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
  fragment CTAGridFragment on CTAGridData {
    settings {
      viewAll
    }
    config {
      items {
        url
        type
        title
        internalPageType
        description
        asset {
          ...AssetFragment
        }
      }
      ctaGridType: type
    }
  }

  fragment CTACarouselFragment on CTACarouselData {
    settings {
      viewAll
    }
    config {
      items {
        url
        type
        title
        internalPageType
        description
        asset {
          ...AssetFragment
        }
      }
      ctaCarouselType: type
    }
  }

  fragment CTAButtonListFragment on CTAButtonListData {
    settings {
      bottomSeparator
    }
    config {
      items {
        url
        type
        title
        internalPageType
        buttonType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment articleListFragment on ArticleListData {
    config {
      articles {
        id
        title
        description
        publishedAt
        createdAt
        authors {
          firstName
          lastName
          thumbnail {
            url
            blurhash
          }
          id
          slug
        }
        tags {
          id
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        prayerCount
        commentCount
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
    settings {
      articleShare
      articleComment
      articlePrayer
      articleSave
    }
  }

  fragment articleListV2Fragment on ArticleListDataV2 {
    config {
      articles {
        id
        title
        description
        publishedAt
        createdAt
        authors {
          firstName
          lastName
          thumbnail {
            url
            blurhash
          }
          id
          slug
        }
        tags {
          id
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        prayerCount
        commentCount
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
    settings {
      articleShare
      articleComment
      articlePrayer
      articleSave
    }
  }

  fragment featuredArticleFragment on FeaturedArticleData {
    settings {
      articleHtml
      articleComment
      articlePrayer
      articleShare
    }
    config {
      article {
        publishedAt
        html
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          name
          key
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        prayerCount
        title
        description
        slug
        commentCount
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment featuredArticleV2Fragment on FeaturedArticleDataV2 {
    config {
      article {
        html
        title
        id
      }
    }
  }

  fragment articleCarouselFragment on ArticleCarouselData {
    settings {
      articleTimestamp
      articleShare
      articleComment
      articlePrayer
      articleSave
    }
    config {
      articles {
        publishedAt
        slug
        id
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        prayerCount
        commentCount
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment articleCarouselV2Fragment on ArticleCarouselDataV2 {
    settings {
      articleTimestamp
      articleShare
      articleComment
      articlePrayer
      articleSave
    }
    config {
      articles {
        publishedAt
        slug
        id
        html
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        prayerCount
        commentCount
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment formDisplayFragment on FormDisplayData {
    config {
      autoFillForm
      form {
        id
        title
        submitCount
        description
        slug
        ctas {
          icon {
            url
            id
          }
          actionUrl
          action
          label
          type
        }
        type
        fields {
          key
          label
          order
          type
          required
          options {
            key
            label
            order
          }
        }
      }
    }
  }

  fragment formCarouselFragment on FormCarouselData {
    config {
      formLimit
      type
      sortBy
      forms {
        id
        title
        description
        slug
        prayerCount
        submitCount
        type
        ctas {
          label
          type
          actionUrl
          action
          icon {
            id
            url
          }
        }
      }
    }
  }

  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }, data: { applySettings: true }) {
      slug
      type
      modules {
        id
        type
        order
        title
        description
        moduleData {
          ... on VideoContinueWatchingCarouselData {
            ...VideoContinueWatchingCarouselFragment
          }
          ... on VideoWatchLaterCarouselData {
            ...VideoWatchLaterCarouselFragment
          }
          ... on VideoCarouselData {
            ...VideoCarouselFragment
          }
          ... on VideoListData {
            ...VideoListFragment
          }
          ... on VideoGridData {
            ...VideoGridFragment
          }
          ... on FeaturedVideoData {
            ...FeaturedVideoFragment
          }
          ... on VideoPlayerData {
            ...VideoPlayerFragment
          }
          ... on VideoPlayerDataV2 {
            ...VideoPlayerV2Fragment
          }
          ... on FeaturedVideoSliderData {
            ...FeaturedVideoSliderFragment
          }
          ... on PodcastListenLaterCarouselData {
            ...PodcastListenLaterCarouselFragment
          }
          ... on PodcastContinueListeningCarouselData {
            ...PodcastContinueListeningCarouselFragment
          }
          ... on PodcastCarouselData {
            ...PodcastCarouselFragment
          }
          ... on PodcastListData {
            ...PodcastListFragment
          }
          ... on PodcastGridData {
            ...PodcastGridFragment
          }
          ... on FeaturedPodcastData {
            ...FeaturedPodcastFragment
          }
          ... on FeaturedPodcastSliderData {
            ...FeaturedPodcastSliderFragment
          }
          ... on PodcastPlayerData {
            ...PodcastPlayerFragment
          }
          ... on CollectionGridData {
            ...CollectionGridFragment
          }
          ... on CollectionCarouselData {
            ...CollectionCarouselFragment
          }
          ... on CollectionListData {
            ...CollectionListFragment
          }
          ... on FeaturedCollectionData {
            ...FeaturedCollectionFragment
          }
          ... on FeaturedCollectionSliderData {
            ...FeaturedCollectionSliderFragment
          }
          ... on ContributorListData {
            ...ContributorListFragment
          }
          ... on ContributorGridData {
            ...ContributorGridFragment
          }
          ... on ContributorCarouselData {
            ...ContributorCarouselFragment
          }
          ... on FeaturedContributorData {
            ...FeaturedContributorFragment
          }
          ... on TopicListData {
            ...TopicListFragment
          }
          ... on TopicGridData {
            ...TopicGridFragment
          }
          ... on TopicCarouselData {
            ...TopicCarouselFragment
          }
          ... on FeaturedTopicData {
            ...FeaturedTopicFragment
          }
          ... on TagCloudData {
            ...TagCloudFragment
          }
          ... on TagCarouselData {
            ...TagCarouselFragment
          }
          ... on FeaturedTagData {
            ...FeaturedTagFragment
          }
          ... on CTABlockData {
            ...CTABlockFragment
          }
          ... on CTABannerWithTextData {
            ...CTABannerWithTextFragment
          }
          ... on HeroData {
            ...HeroFragment
          }
          ... on CTABannerData {
            ...CTABannerFragment
          }
          ... on DonationData {
            ...DonationFragment
          }
          ... on CopyHeavyData {
            ...CopyHeavyFragment
          }
          ... on CopyHeavyHeroData {
            ...CopyHeavyHeroFragment
          }
          ... on QuoteData {
            ...QuoteFragment
          }
          ... on InformationData {
            ...informationFragment
          }
          ... on LeadGenData {
            ...leadGenFragment
          }
          ... on ContactUsData {
            ...contactUsFragment
          }
          ... on FAQData {
            ...faqsFragment
          }
          ... on SpacerData {
            ...spaceFragment
          }
          ... on CTAGridData {
            ...CTAGridFragment
          }
          ... on CTACarouselData {
            ...CTACarouselFragment
          }
          ... on CTAButtonListData {
            ...CTAButtonListFragment
          }
          ... on ArticleCarouselData {
            ...articleCarouselFragment
          }
          ... on ArticleCarouselDataV2 {
            ...articleCarouselV2Fragment
          }
          ... on ArticleListData {
            ...articleListFragment
          }
          ... on ArticleListDataV2 {
            ...articleListV2Fragment
          }
          ... on FeaturedArticleData {
            ...featuredArticleFragment
          }
          ... on FeaturedArticleDataV2 {
            ...featuredArticleV2Fragment
          }
          ... on FormDisplayData {
            ...formDisplayFragment
          }
          ... on FormCarouselData {
            ...formCarouselFragment
          }
        }
      }
    }
  }
`;

export const GET_WORKSPACE_APP = gql`
  query workspaceApp($where: WorkspaceAppUniqueInput!) {
    workspaceApp(where: $where) {
      id
      url
    }
  }
`;
