import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { authClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import './auth.less';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    client: authClient,
    onError() {} // Always write this method for error handling in all mutation.
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    history?.replace('/');
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim()
      };
      const response = await loginMutate({
        variables: { data: { ...formValues } }
      });
      const accessToken = get(response, 'data.loginAdmin.accessToken');
      const userData = get(response, 'data.loginAdmin.user');
      const refreshToken = get(response, 'data.loginAdmin.refreshToken');

      if (successCallback) {
        successCallback(accessToken, userData, refreshToken);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="login-section">
      <div className="login-img">
        <img src="/logo.png" className="login-logo" alt="logo" />
      </div>
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-left mb-0">
              <h2 className="text-left">Login</h2>
              <p>Welcome to media.platform</p>
            </div>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-24"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>

              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-8">
                <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default Login;
