import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './styles/editorPreview.less';

const EditorPreview = ({
  className,
  disabled,
  value,
  onChange,
  resize = true,
  ...rest
}) => {
  return (
    <SunEditor
      height="100%"
      disable={disabled}
      setOptions={{
        resizeEnable: resize,
        minHeight: 200,
        height: '100%',
        buttonList: [
          [
            'formatBlock',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'list',
            'link',
            'fullScreen',
            'codeView',
            'preview',
            'undo',
            'redo'
          ]
        ],
        formats: ['p', 'blockquote', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        charCounter: true
      }}
      value={value}
      setContents={value}
      onChange={(val) => {
        if (val === '<p><br></p>') onChange('');
        else onChange(val);
      }}
      className={`sun-editor ${className} ${!resize ? 'fixed-height' : ''}`}
      {...rest}
    />
  );
};
export default EditorPreview;
