import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($data: UserUpdateInput!, $where: UserUniqueInput!) {
    updateUser(data: $data, where: $where) {
      message
    }
  }
`;

export const REQUEST_EXPORT_USERS = gql`
  mutation requestUsersExport($filter: RequestUsersExportFilter) {
    requestUsersExport(filter: $filter) {
      message
    }
  }
`;
