import { useLazyQuery, useMutation } from '@apollo/client';
import { uniqueId } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useParams } from 'react-router-dom';
import {
  DELETE_PAGE_MODULE,
  UPDATE_PAGE_MODULE_ORDER
} from '../graphql/Mutations';
import { GET_PAGE_DETAILS, GET_PAGE_MODULE_TYPES } from '../graphql/Queries';
import { usePagePreview } from './PreviewPageProvider';

const ctx = createContext();

export const useEditPage = () => useContext(ctx);

export const FORM_TYPES = {
  EDIT: 'EDIT',
  ADD: 'ADD'
};

const initialForm = {
  index: '',
  type: '',
  moduleId: '',
  defaultValues: null
};

const initialSettings = {
  title: '',
  slug: '/',
  description: '',
  metaHeader: '',
  metaFooter: '',
  apps: []
};

const initialState = {
  pageType: '',
  isDefaultPage: false,
  settings: {
    ...initialSettings
  },
  modules: [],
  selectionType: '',
  addIndex: '',
  form: {
    ...initialForm
  }
};

const SET_SELECTION_TYPE = 'SET_SELECTION_TYPE';
const SET_ADD_INDEX = 'SET_ADD_INDEX';
const ADD_MODULE = 'ADD_MODULE';
const DELETE_MODULE = 'DELETE_MODULE';
const MOVE_MODULE = 'MOVE_MODULE';
const SET_FORM = 'SET_FORM';
const SET_MODULES = 'SET_MODULES';
const CLEAR_RIGHT_PANEL = 'CLEAR_RIGHT_PANEL';
const UPDATE_MODULE = 'UPDATE_MODULE';
const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
const SET_IS_DEFAULT_PAGE = 'SET_IS_DEFAULT_PAGE';
const SET_PAGE_TYPE = 'SET_PAGE_TYPE';

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTION_TYPE: {
      return { ...state, selectionType: payload, addIndex: 0 };
    }
    case SET_FORM: {
      return { ...state, form: { ...payload } };
    }
    case SET_ADD_INDEX: {
      return { ...state, addIndex: payload };
    }
    case ADD_MODULE: {
      const modulesCopy = [...state.modules];
      const addIndex = state.addIndex ?? modulesCopy.length;
      modulesCopy.splice(addIndex, 0, payload);
      return {
        ...state,
        modules: [...modulesCopy],
        selectionType: '',
        addIndex: 0
      };
    }
    case DELETE_MODULE: {
      const modulesCopy = [...state.modules];
      modulesCopy.splice(payload, 1);

      if (state.form.index === payload && state.selectionType) {
        return {
          ...state,
          modules: modulesCopy,
          form: { ...initialForm },
          selectionType: '',
          addIndex: ''
        };
      }
      return { ...state, modules: modulesCopy };
    }
    case MOVE_MODULE: {
      const { sourceIndex, destinationIndex } = payload;
      const modulesCopy = [...state.modules];

      const [sourceData] = modulesCopy.splice(sourceIndex, 1);
      modulesCopy.splice(destinationIndex, 0, sourceData);

      return {
        ...state,
        modules: modulesCopy
      };
    }
    case SET_MODULES: {
      return { ...state, modules: payload };
    }
    case CLEAR_RIGHT_PANEL: {
      return {
        ...state,
        selectionType: '',
        addIndex: '',
        form: {
          ...initialForm
        }
      };
    }
    case UPDATE_MODULE: {
      const newModules = state.modules.map((module, index) => {
        if (state.form.index === index) {
          return { ...module, moduleData: payload };
        }
        return module;
      });

      return {
        ...state,
        modules: newModules
      };
    }
    case UPDATE_SETTINGS: {
      return {
        ...state,
        settings: payload
      };
    }
    case SET_IS_DEFAULT_PAGE: {
      return {
        ...state,
        isDefaultPage: payload
      };
    }
    case SET_PAGE_TYPE: {
      return {
        ...state,
        pageType: payload
      };
    }
    default:
      return state;
  }
};

const EditPageProvider = ({ children }) => {
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isAdding = useMemo(() => state.form.type === FORM_TYPES.ADD, [state]);
  const isEditing = useMemo(() => state.form.type === FORM_TYPES.EDIT, [state]);

  const { refetch } = usePagePreview();

  const updateSettings = useCallback(
    ({
      slug,
      title,
      description,
      metaHeader,
      metaFooter,
      permissions,
      allowDelete,
      apps
    }) => {
      const payload = {
        title: title || '',
        slug: `/${slug ?? ''}`,
        description: description || '',
        metaHeader: metaHeader || '',
        metaFooter: metaFooter || '',
        permissions: permissions || [],
        allowDelete,
        apps
      };
      dispatch({ type: UPDATE_SETTINGS, payload });
    },
    [dispatch]
  );

  const handleResponse = useCallback(
    (data) => {
      if (!data) return;
      const { pageAdmin } = data;
      if (!pageAdmin) return;

      const { modules, isDefault, type, ...settings } = pageAdmin;
      updateSettings({
        ...settings,
        apps: settings?.apps?.map(({ id: appId, name }) => ({
          label: name,
          value: appId
        }))
      });
      const newModules = modules.map((module) => ({
        type: module?.type,
        id: uniqueId(),
        moduleData: module
      }));
      dispatch({ type: SET_IS_DEFAULT_PAGE, payload: isDefault });
      dispatch({ type: SET_MODULES, payload: newModules });
      dispatch({ type: SET_PAGE_TYPE, payload: type });
    },
    [dispatch, updateSettings]
  );

  const [fetchPageDetails, { loading: fetchingPageDetails }] = useLazyQuery(
    GET_PAGE_DETAILS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [
    fetchPageModules,
    {
      loading: loadingPageModules,
      data: { pageModuleTypes: pageModules = [] } = {}
    }
  ] = useLazyQuery(GET_PAGE_MODULE_TYPES);

  const [updatePageModuleOrder, { loading: isMoving }] = useMutation(
    UPDATE_PAGE_MODULE_ORDER
  );

  const [deletePageModule] = useMutation(DELETE_PAGE_MODULE);

  const fetchPageData = useCallback(() => {
    fetchPageDetails({ variables: { id } })
      .then((res) => handleResponse(res.data))
      .catch();
  }, [id, handleResponse]);

  useEffect(() => {
    fetchPageData();
  }, [fetchPageData]);

  const setSelectionType = (type) => {
    dispatch({ type: SET_SELECTION_TYPE, payload: type });
  };

  const setAddIndex = (index) => {
    dispatch({ type: SET_ADD_INDEX, payload: index });
  };

  const addModule = (data) => {
    dispatch({ type: ADD_MODULE, payload: data });
  };

  const deleteModule = (index) => {
    dispatch({ type: DELETE_MODULE, payload: index });
  };

  const moveModule = useCallback(
    (sourceIndex, destinationIndex) => {
      const fallbackPayload = {
        sourceIndex: destinationIndex,
        destinationIndex: sourceIndex
      };
      dispatch({
        type: MOVE_MODULE,
        payload: { sourceIndex, destinationIndex }
      });
      const module = state.modules[sourceIndex];
      if (module && module?.moduleData) {
        updatePageModuleOrder({
          variables: {
            id: module.moduleData.id,
            order: destinationIndex + 1
          }
        })
          .then(() => {
            refetch();
          })
          .catch(() => {
            dispatch({ type: MOVE_MODULE, payload: fallbackPayload });
          });
      }
    },
    [state.modules]
  );

  const setForm = (data) => {
    dispatch({ type: SET_FORM, payload: { ...initialForm, ...data } });
  };

  const clearRightPanel = () => {
    dispatch({ type: CLEAR_RIGHT_PANEL });
  };

  const updateModule = (data) => {
    dispatch({ type: UPDATE_MODULE, payload: data });
  };

  const handlers = {
    setSelectionType,
    fetchPageModules,
    setAddIndex,
    addModule,
    deleteModule,
    moveModule,
    setForm,
    clearRightPanel,
    updateModule,
    updatePageModuleOrder,
    deletePageModule,
    updateSettings,
    refetchPageDetails: fetchPageData
  };

  const data = {
    ...state,
    isAdding,
    loadingPageModules,
    pageModules,
    fetchingPageDetails,
    isEditing,
    isMoving
  };

  return (
    <ctx.Provider value={{ ...data, ...handlers }}>{children}</ctx.Provider>
  );
};

export default EditPageProvider;
