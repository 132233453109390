import {
  CommentOutlined,
  FlagOutlined,
  LikeOutlined,
  StarOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Avatar, Button, Flex, Tooltip } from 'antd';
import { isFunction } from 'lodash';
import moment from 'moment';
import {
  Check,
  CheckSquare,
  EyeSlash,
  HighlighterCircle,
  Trash
} from 'phosphor-react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  COMMENTS_INSTANCE_TYPES,
  COMMENTS_SORT_FIELD,
  LIST_TYPES,
  MODULES,
  ROUTES
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import Image from '../../../components/Image';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import Count from '../posts/components/Count';
import ModerationTags from '../posts/components/ModerationTags';
import { UPDATE_COMMENT } from './graphql/Mutations';
import { COMMENTS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.commentThreadAdmin?.comments ?? [],
  count: data?.commentThreadAdmin?.count ?? 0
});

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search: _search,
  ...rest
}) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

function Attachment({ url }) {
  return (
    <div className="image-wrapper">
      <Image src={url} alt="comment image" />
    </div>
  );
}

function Action({ defaultValue, id, keyField, icon, label, removeItem, all }) {
  const [checked, setChecked] = useState(defaultValue);
  const [update, { loading }] = useMutation(UPDATE_COMMENT, {
    onError() {
      setChecked((prev) => !prev);
    },
    onCompleted(response) {
      if (!all && response?.updateCommentAdmin && isFunction(removeItem)) {
        removeItem(id);
      }
    }
  });
  const handleChange = (value) => {
    update({
      variables: {
        where: { id },
        data: { [keyField]: value }
      }
    });
    setChecked(value);
  };

  return (
    <div className="action">
      <Switch
        reverse
        disabled={loading}
        checked={checked}
        onChange={handleChange}
        label={
          <Flex align="center" gap={4}>
            {icon} {label}
          </Flex>
        }
      />
    </div>
  );
}

const ACTIONS = [
  {
    key: 'isHighlighted',
    icon: <HighlighterCircle size={18} />,
    label: 'Highlighted'
  },
  {
    key: 'isAccepted',
    icon: <Check size={18} />,
    label: 'Accepted'
  },
  {
    key: 'isHidden',
    icon: <EyeSlash size={18} />,
    label: 'Hidden'
  },
  {
    key: 'isDeleted',
    icon: <Trash size={18} />,
    label: 'Deleted'
  }
];

function Actions({ id, removeItem, all, ...rest }) {
  return (
    <Flex align="center" gap={8} onClick={(e) => e?.stopPropagation()}>
      {ACTIONS.map(({ key, icon, label }) => (
        <Action
          key={key}
          keyField={key}
          label={label}
          icon={icon}
          defaultValue={rest[key]}
          id={id}
          removeItem={removeItem}
          all={all}
        />
      ))}
    </Flex>
  );
}

function CommentCard({
  comment,
  createdBy,
  createdAt,
  likesCount,
  starsCount,
  replyCount,
  flagsCount,
  moderationResponse,
  onSelect,
  id,
  selectedCard,
  isHidden,
  isAccepted,
  isHighlighted,
  isDeleted,
  mediaUrl,
  mediaModerationResponse,
  removeItem,
  all
}) {
  return (
    <div
      className={`comment-card ${selectedCard === id ? 'selected' : ''}`}
      onClick={() => onSelect(id, Boolean(replyCount))}
    >
      <Flex className="mb-8" gap={8} align="center">
        <Avatar src={createdBy?.profileImage}>
          {[createdBy?.firstName, createdBy?.lastName]
            .filter(Boolean)
            .map((name) => name[0]?.toUpperCase())
            .join('')}
        </Avatar>
        <Flex flex={1} vertical gap={2}>
          <p className="heading-h5">
            {createdBy?.firstName} {createdBy?.lastName}
          </p>
          <Tooltip title={moment(createdAt).format('DD[th] MMM, YYYY hh:mm A')}>
            <p className="created-at">{moment(createdAt).fromNow()}</p>
          </Tooltip>
        </Flex>
      </Flex>
      <div className="comment-card-body">
        {comment && <p className="mb-8 comment-text">{comment}</p>}
        {moderationResponse?.classes?.length > 0 && (
          <ModerationTags data={moderationResponse.classes} />
        )}
        <div className="attachment">
          {mediaUrl && <Attachment url={mediaUrl} />}
        </div>
        {mediaModerationResponse?.classes?.length > 0 && (
          <ModerationTags data={mediaModerationResponse.classes} />
        )}
      </div>
      <Flex align="center" gap={8} className="stats-wrapper">
        <Flex align="center" flex={1} gap={8}>
          <Count icon={<LikeOutlined />} value={likesCount} />
          <Count icon={<CommentOutlined />} value={replyCount} />
          <Count icon={<StarOutlined />} value={starsCount} />
          <Count icon={<FlagOutlined />} value={flagsCount} />
        </Flex>
        <Actions
          id={id}
          isHidden={isHidden}
          isAccepted={isAccepted}
          isHighlighted={isHighlighted}
          isDeleted={isDeleted}
          removeItem={removeItem}
          all={all}
        />
      </Flex>
    </div>
  );
}

const NestedComments = memo(
  ({
    filters: filters$,
    commentsIds,
    parentId,
    index = -1,
    onSelect,
    searchParams
  }) => {
    const [actionFilters, setActionFilters] = useState({
      all: true,
      isAccepted: false,
      isDeleted: false,
      isHidden: false,
      isHighlighted: false
    });

    const filters = useMemo(
      () => ({
        ...filters$,
        ...(!actionFilters.all && {
          isAccepted: actionFilters.isAccepted,
          isDeleted: actionFilters.isDeleted,
          isHidden: actionFilters.isHidden,
          isHighlighted: actionFilters.isHighlighted
        }),
        parentId,
        ...(searchParams && {
          instanceType: searchParams.type,
          instanceId: searchParams.id
        })
      }),
      [filters$, parentId, searchParams, actionFilters]
    );

    const nextIndex = index + 1;

    const nextParentId = commentsIds[nextIndex];

    const handleCardSelect = (id, hasComments) => {
      onSelect(id, index, hasComments);
    };

    return (
      <>
        <div className="comments-container">
          <Flex gap={8} align="center" className="filters-wrapper">
            <Switch
              reverse
              checked={actionFilters.all}
              onChange={(value) =>
                setActionFilters((prev) => ({ ...prev, all: value }))
              }
              label={
                <Flex align="center" gap={4}>
                  <CheckSquare size={18} /> All
                </Flex>
              }
            />
            {!actionFilters.all && (
              <>
                {ACTIONS.map(({ key, label, icon }) => (
                  <div key={key} className="action">
                    <Switch
                      reverse
                      checked={actionFilters[key]}
                      onChange={(value) =>
                        setActionFilters((prev) => ({ ...prev, [key]: value }))
                      }
                      label={
                        <Flex align="center" gap={4}>
                          {icon} {label}
                        </Flex>
                      }
                    />
                  </div>
                ))}
              </>
            )}
          </Flex>

          <div className="comments">
            <PageList
              filters={filters}
              listMode={LIST_TYPES.GRID}
              query={COMMENTS}
              variablesSelector={variablesSelector}
              dataSelector={dataSelector}
              GridProps={{
                renderItem: (record, { removeItem }) => (
                  <CommentCard
                    {...record}
                    selectedCard={nextParentId}
                    onSelect={handleCardSelect}
                    removeItem={removeItem}
                    all={actionFilters.all}
                  />
                ),
                breakPoints: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
              }}
            />
          </div>
        </div>
        {nextParentId && (
          <NestedComments
            filters={filters$}
            commentsIds={commentsIds}
            parentId={nextParentId}
            index={nextIndex}
            onSelect={onSelect}
            searchParams={searchParams}
          />
        )}
      </>
    );
  }
);

function Comments() {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    const params = Object.fromEntries(new URLSearchParams(location?.search));

    if (
      params &&
      params.type &&
      params.id &&
      Object.values(COMMENTS_INSTANCE_TYPES).includes(params.type)
    ) {
      return params;
    }

    return null;
  }, [location]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: COMMENTS_SORT_FIELD,
      field: COMMENTS_SORT_FIELD[0].value
    }
  });

  const [commentsIds, setCommentIds] = useState([]);

  const handleCommentSelect = useCallback(
    (id, index, hasComments) => {
      const ids = [...commentsIds];

      const newIds = ids.filter((_, i) => i <= index);
      if (hasComments) {
        newIds[index + 1] = id;
      }

      setCommentIds(newIds);
    },
    [commentsIds]
  );

  const handleClear = () => {
    setCommentIds([]);
    history?.push(ROUTES.COMMENTS);
  };

  return (
    <>
      <PageHeader menu={MODULES?.MODERATION} />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false,
          search: false
        }}
      >
        {searchParams && (
          <Button
            onClick={handleClear}
            type="text"
            htmlType="submit"
            className="text-btn"
            size="middle"
          >
            Clear Filters
          </Button>
        )}
      </FilterBar>
      <div className="nested-comments-container">
        <NestedComments
          filters={filterProps.filters}
          commentsIds={commentsIds}
          onSelect={handleCommentSelect}
          searchParams={searchParams}
        />
      </div>
    </>
  );
}

export default Comments;
