import { IdcardOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import { capitalize, forEach } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  MODULES,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import useCheckPermission from '../../../hooks/useCheckPermission';
import UserProfile from '../header/UserProfile';
import './sidebar.less';

function Sidebar({ location: { pathname }, history, collapsed }) {
  const {
    state: { workspace, app, currentUser: { roles = [] } = null }
  } = useContext(AppContext);
  const [openKeys, setOpenKeys] = useState([]);

  const subMenus = [
    {
      moduleKey: MODULES?.CONTENT_UNITS,
      routes: [
        ROUTES?.VIDEOS_MODULE,
        ROUTES?.PODCAST_MODULE,
        ROUTES?.FORMS,
        ROUTES?.ARTICLES_MODULE,
        ROUTES?.COMMUNITY_MODULE
      ]
    },
    {
      moduleKey: MODULES?.ASSETS,
      routes: [
        ROUTES?.IMAGES,
        ROUTES?.AUDIO,
        ROUTES?.DOCUMENT,
        ROUTES?.VIDEOS,
        ROUTES?.TEXT,
        ROUTES?.ICONS
      ]
    },
    {
      moduleKey: MODULES?.LABELS,
      routes: [
        ROUTES?.TOPICS,
        ROUTES?.TAGS,
        ROUTES?.CONTRIBUTOR,
        ROUTES?.COLLECTIONS,
        ROUTES?.SOURCES
      ]
    },
    {
      moduleKey: MODULES?.REPORTS,
      routes: [ROUTES?.LEAD_GENS, ROUTES?.CONTACT_INQUIRY, ROUTES?.EXPORT_JOBS]
    },
    {
      moduleKey: MODULES?.MODERATION,
      routes: [ROUTES?.POSTS, ROUTES?.COMMENTS]
    },
    {
      moduleKey: MODULES?.UI_CONFIGURATION,
      routes: [ROUTES?.MENUS, ROUTES?.PAGES_MODULE, ROUTES?.UI_LABELS]
    }
  ];

  const getOpenKeys = () => {
    const openKeysClone = openKeys;
    forEach(subMenus, (item) => {
      forEach(item?.routes, (route) => {
        if (
          route !== ROUTES?.MAIN &&
          pathname?.includes(route) &&
          !openKeysClone?.includes(item?.moduleKey)
        ) {
          openKeysClone?.push(item?.moduleKey);
          setOpenKeys(openKeysClone);
        }
      });
    });
  };

  useEffect(() => {
    getOpenKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onMenuSelect = ({ key }) => {
    history?.push(key);
  };

  function getItem({ label, key, icon, children, type }) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }
  const isContentManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isLabelManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isPlatformManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isUserManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isRoleManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isReportManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const uiConfigManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const uiPageManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const menuItems = [
    getItem({
      label: MODULES?.DASHBOARD,
      key: ROUTES?.DASHBOARD_LANDING
    }),
    getItem({
      type: 'divider'
    }),
    app &&
      (uiConfigManagementAllowed || uiPageManagementAllowed) &&
      getItem({
        label: MODULES?.UI_CONFIGURATION,
        key: MODULES?.UI_CONFIGURATION,
        children: [
          uiConfigManagementAllowed &&
            getItem({
              label: MODULES?.MENUS,
              key: ROUTES?.MENUS
            }),
          uiPageManagementAllowed &&
            getItem({
              label: MODULES?.PAGES,
              key: ROUTES?.PAGES_MODULE
            }),
          uiConfigManagementAllowed &&
            getItem({
              label: MODULES?.UI_LABELS,
              key: ROUTES?.UI_LABELS
            })
        ]
      }),
    isContentManagementAllowed &&
      getItem({
        label: MODULES?.CONTENT_UNITS,
        key: MODULES?.CONTENT_UNITS,
        children: [
          getItem({
            label: MODULES?.VIDEOS,
            key: ROUTES?.VIDEOS_MODULE
          }),
          getItem({
            label: MODULES?.PODCAST,
            key: ROUTES?.PODCAST_MODULE
          }),
          getItem({
            label: MODULES?.FORMS,
            key: ROUTES?.FORMS
          }),
          getItem({ label: MODULES?.ARTICLES, key: ROUTES?.ARTICLES_MODULE }),
          getItem({
            label: MODULES?.COMMUNITIES,
            key: ROUTES?.COMMUNITY_MODULE
          })
        ]
      }),
    // TODO: Handle permissions once added in BE
    getItem({
      label: MODULES?.MODERATION,
      key: MODULES?.MODERATION,
      children: [
        getItem({
          label: MODULES?.POSTS,
          key: ROUTES?.POSTS
        }),
        getItem({
          label: MODULES?.COMMENTS,
          key: ROUTES?.COMMENTS
        })
      ]
    }),
    isAssetManagementAllowed &&
      getItem({
        label: MODULES?.ASSETS,
        key: MODULES?.ASSETS,
        children: [
          getItem({ label: MODULES?.IMAGES, key: ROUTES?.IMAGES }),
          getItem({ label: MODULES?.VIDEOS, key: ROUTES?.VIDEOS }),
          getItem({ label: MODULES?.DOCUMENT, key: ROUTES?.DOCUMENT }),
          getItem({ label: MODULES?.AUDIO, key: ROUTES?.AUDIO }),
          getItem({ label: MODULES?.TEXT, key: ROUTES?.TEXT }),
          getItem({ label: MODULES?.ICONS, key: ROUTES?.ICONS })
        ]
      }),
    isLabelManagementAllowed &&
      getItem({
        label: MODULES?.LABELS,
        key: MODULES?.LABELS,
        children: [
          getItem({ label: MODULES?.TOPICS, key: ROUTES?.TOPICS }),
          getItem({ label: MODULES?.TAGS, key: ROUTES?.TAGS }),
          getItem({ label: MODULES?.CONTRIBUTOR, key: ROUTES?.CONTRIBUTOR }),
          getItem({ label: MODULES?.COLLECTIONS, key: ROUTES?.COLLECTIONS }),
          getItem({ label: MODULES?.SOURCES, key: ROUTES?.SOURCES })
        ]
      }),
    isReportManagementAllowed &&
      getItem({
        label: MODULES?.REPORTS,
        key: MODULES?.REPORTS,
        children: [
          getItem({
            label: MODULES?.LEAD_GENS,
            key: ROUTES?.LEAD_GENS
          }),
          getItem({
            label: MODULES?.CONTACT_INQUIRY,
            key: ROUTES?.CONTACT_INQUIRY
          }),
          getItem({
            label: MODULES?.EXPORT_JOBS,
            key: ROUTES?.EXPORT_JOBS
          })
        ]
      }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.USERS,
        key: ROUTES?.USERS
      }),
    getItem({
      type: 'divider'
    }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.DONATIONS,
        key: ROUTES?.DONATIONS
      }),
    getItem({
      type: 'divider'
    }),
    getItem({
      label: MODULES?.WORKSPACE_SETTINGS,
      key: MODULES?.WORKSPACE_SETTINGS,
      children: [
        isUserManagementAllowed &&
          getItem({
            label: MODULES?.WORKSPACE_USERS,
            key: ROUTES?.WORKSPACE_USERS
          }),
        isRoleManagementAllowed &&
          getItem({
            label: MODULES?.ROLES,
            key: ROUTES?.ROLES
          }),
        isPlatformManagementAllowed &&
          getItem({
            label: MODULES?.PLATFORMS,
            key: ROUTES?.PLATFORMS
          })
      ]
    })
  ];

  const profileItems = [
    getItem({
      label: <UserProfile collapsed={collapsed} />,
      key: MODULES?.PROFILE,
      children: [
        [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(roles?.[0]) &&
          getItem({
            label: (
              <Link to={ROUTES?.ADMIN_USERS}>
                {capitalize(MODULES?.ADMINS)}
              </Link>
            ),
            key: ROUTES?.ADMIN_USERS,
            icon: <UserOutlined />
          }),
        getItem({
          label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
          key: ROUTES?.PROFILE,
          icon: <IdcardOutlined />
        }),
        getItem({
          label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
          key: ROUTES?.LOGOUT,
          icon: <LoginOutlined />
        })
      ]
    })
  ];

  return (
    <div className="sidebar">
      <img
        onClick={() => history.push(ROUTES.DASHBOARD_LANDING)}
        src={collapsed ? '/logo-collapsed.png' : '/logo.png'}
        className={`sidebar-logo ${collapsed ? 'collapsed' : ''}`}
        alt="sidebar logo"
      />
      <Divider className="logo-divider" />
      <div className="sidebar-scroll">
        {workspace && (
          <Menu
            className="menu-bar"
            theme="light"
            mode="inline"
            selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
            defaultSelectedKeys={[ROUTES?.MAIN]}
            items={menuItems}
            inlineIndent={0}
            openKeys={openKeys}
            onOpenChange={(openKeysArray) => setOpenKeys(openKeysArray)}
            onClick={onMenuSelect}
          />
        )}
      </div>
      <div className="profile-menu">
        <Menu
          className="profile-bar"
          theme="light"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          items={profileItems}
        />
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
