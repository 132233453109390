import { filter, isEmpty, sortBy } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import {
  PERMISSIONS_ORDER,
  PERMISSION_ROUTES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../common/constants';
import useCheckPermission from './useCheckPermission';

const useRedirectUser = () => {
  const {
    state: { userPermissions }
  } = useContext(AppContext);

  const isAppAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const redirectUser = (permissions = userPermissions) => {
    const findAllowedModules = filter(
      permissions,
      (item) => item?.level !== WORKSPACE_ROLE_LEVEL.NONE && item
    );

    const sortedModules = sortBy(findAllowedModules, [
      (allowedModule) => {
        return PERMISSIONS_ORDER[allowedModule.key];
      }
    ]);

    if (!isEmpty(sortedModules)) {
      if (
        (sortedModules?.[0]?.key ===
          WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT ||
          sortedModules?.[0]?.key ===
            WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT) &&
        !isAppAllowed
      ) {
        if (
          sortedModules?.[1]?.key &&
          sortedModules?.[1]?.key !==
            WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT &&
          sortedModules?.[1]?.key !== WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT
        ) {
          return PERMISSION_ROUTES[sortedModules?.[1]?.key];
        }
        return ROUTES.NO_ACCESS;
      }
      return PERMISSION_ROUTES[sortedModules?.[0]?.key];
    }
    return ROUTES.NO_ACCESS;
  };

  return {
    redirectUser
  };
};

export default useRedirectUser;
