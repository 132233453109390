import {
  EditOutlined,
  EyeFilled,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Space, Tooltip } from 'antd';
import isFunction from 'lodash/isFunction';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import videoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  ASSET_TYPE,
  LIST_TYPES,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Select } from '../videos/components/FormInputs';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import { SelectUsers } from './components';
import { INVITE_MEMBERS } from './graphql/Mutations';
import { GET_COMMUNITIES } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  tagIds,
  topicIds
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    tagIds,
    topicIds
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ communitiesAdmin }) => ({
  data: communitiesAdmin?.communities ?? [],
  count: communitiesAdmin?.count ?? 0
});

const initialValues = {
  members: []
};

function InviterMembersForm({ communityId, onSuccess, onClose }) {
  const [form] = Form.useForm();
  const [inviteMembers, { loading }] = useMutation(INVITE_MEMBERS);

  const handleSubmit = ({ members }) => {
    const payload = {
      where: {
        id: communityId
      },
      data: {
        emails: members?.map(({ value }) => value) ?? []
      }
    };
    inviteMembers({
      variables: payload
    })
      .then((data) => {
        form?.resetFields();
        if (isFunction(onSuccess)) onSuccess(data);
      })
      .catch();
  };

  const handleCancel = () => {
    if (isFunction(onClose)) onClose();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Form.Item label="Members" name="members">
        <SelectUsers placeholder="Select Members" />
      </Form.Item>

      <div className="d-flex justify-end button-section mb-8">
        <Space>
          <Button
            disabled={loading}
            loading={loading}
            type="text"
            htmlType="submit"
            className="text-btn mr-8"
            size="middle"
          >
            Save
          </Button>

          <Button
            disabled={loading}
            type="text"
            className="text-btn2"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Space>
      </div>
    </Form>
  );
}

function InviteMembersModal({ open, onClose, communityId, onSuccess }) {
  return (
    <Modal
      footer={null}
      title="Invite Members"
      open={open}
      onCancel={onClose}
      width={1080}
      destroyOnClose
    >
      <InviterMembersForm
        communityId={communityId}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Modal>
  );
}

function Action({ id, refetch, isViewOnly, isAddEditAllowed }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleEdit = () => {
    history.push(`${ROUTES?.COMMUNITY_MODULE}/${id}/edit`);
  };

  const handleInvite = () => {
    setOpen(true);
  };

  const handleModalClose = () => setOpen(false);

  const handleSuccess = () => {
    refetch();
    setOpen(false);
  };

  return (
    <>
      <InviteMembersModal
        open={open}
        onClose={handleModalClose}
        communityId={id}
        onSuccess={handleSuccess}
      />
      <div className="d-flex align-center">
        {isAddEditAllowed && (
          <Tooltip title="Invite Members">
            <Button type="text" className="text-btn" onClick={handleInvite}>
              <UsergroupAddOutlined />
            </Button>
          </Tooltip>
        )}
        <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Community`}>
          <Button type="text" className="text-btn" onClick={handleEdit}>
            {isViewOnly ? <EyeFilled /> : <EditOutlined />}
          </Button>
        </Tooltip>
      </div>
    </>
  );
}

export default function Communities() {
  const [topics, setTopics] = useState([]);
  const [tags, setTags] = useState([]);
  const filterProps = useFilterBar();
  const history = useHistory();

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const columns = useMemo(
    () => ({ showPreview, refresh }) => [
      {
        title: 'Image',
        dataIndex: 'imageUrl',
        render: (value, { title }) => (
          <Image
            className="group-img-table pointer"
            blurHash={value?.blurhash}
            src={value ?? videoPlaceholder}
            alt={title}
            onClick={() => {
              showPreview({
                type: ASSET_TYPE.IMAGE,
                url: value ?? videoPlaceholder
              });
            }}
          />
        ),
        width: 110
      },
      {
        title: 'Theme',
        dataIndex: 'primaryColor',
        render: (value) => {
          return value ? (
            <div
              className="color-list mr-0"
              style={{
                // Used inline style as its dynamic value
                backgroundColor: value
              }}
            />
          ) : (
            '-'
          );
        },
        width: 110
      },
      {
        title: 'Title',
        dataIndex: 'title',
        ellipsis: true
      },
      {
        title: 'Description',
        dataIndex: 'description',
        ellipsis: true
      },
      {
        title: 'Members',
        dataIndex: 'membersCount',
        width: 100
      },
      ...(isAssetAllowed
        ? [
            {
              title: 'Action',
              dataIndex: 'id',
              render: (_, record) => (
                <Action
                  {...record}
                  isAddEditAllowed={isAddEditAllowed}
                  isViewOnly={isViewOnly}
                  refetch={refresh}
                />
              ),
              width: 150
            }
          ]
        : [])
    ],
    [isAssetAllowed, isViewOnly, isAddEditAllowed]
  );

  const handleShowModal = () => {
    history.push(`${ROUTES?.COMMUNITY_MODULE}/add`);
  };

  const filters = useMemo(
    () => ({ ...filterProps.filters, topicIds: topics, tagIds: tags }),
    [filterProps.filters, topics, tags]
  );

  return (
    <>
      <PageHeader
        menu={MODULES?.CONTENT_UNITS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed && isAssetAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Communities' }}
        show={{
          listModes: false
        }}
      >
        <Select
          mode="multiple"
          placeholder="Topics"
          query={GET_TOPICS}
          popupMatchSelectWidth={false}
          variablesSelector={(filter) => ({ filter })}
          dataSelector={(data) =>
            data?.topicsAdmin?.topics?.map(({ id, name }) => ({
              label: name,
              value: id
            })) ?? 0
          }
          keys={{
            data: 'topicsAdmin',
            records: 'topics',
            count: 'count'
          }}
          labelInValue={false}
          value={topics}
          onChange={(value) => setTopics(value)}
          className="minimum-w-dropdown"
        />
        <Select
          mode="multiple"
          placeholder="Tags"
          query={GET_TAGS}
          variablesSelector={(filter) => ({ filter })}
          dataSelector={(data) =>
            data?.tagsAdmin?.tags?.map(({ id, name }) => ({
              label: name,
              value: id
            })) ?? []
          }
          keys={{
            data: 'tagsAdmin',
            records: 'tags',
            count: 'count'
          }}
          labelInValue={false}
          value={tags}
          onChange={(value) => setTags(value)}
          className="minimum-w-dropdown"
        />
      </FilterBar>

      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_COMMUNITIES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
      />
    </>
  );
}
