import { CopyOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Modal, Table, Tag, Typography } from 'antd';
import React from 'react';
import { VERIFY_APP_CUSTOM_DOMAIN } from '../../app/components/sidebar/graphql/Mutation';
import { STATUS_COLORS } from '../../common/constants';
import { copyToClipboard } from '../../common/utils';

const { Text } = Typography;

const DomainRecordsModal = ({
  data = [],
  openModal,
  setOpenModal,
  appId,
  refetch
}) => {
  const columns = [
    {
      title: 'Record type',
      dataIndex: 'type',
      ellipsis: true,
      render: (value) => {
        return <b className="module-name">{value}</b>;
      }
    },
    {
      title: 'Host',
      dataIndex: 'name',
      width: '30%',
      ellipsis: true,
      render: (value) => {
        return <b className="module-name">{value}</b>;
      }
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '30%',
      render: (value) => {
        return (
          <div className="d-flex">
            <b className="module-name records-value">
              <Text title={value}>{value}</Text>
            </b>
            <CopyOutlined
              onClick={() => copyToClipboard(value)}
              title="Copy domain"
            />
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        return (
          <Tag color={STATUS_COLORS[status]} className="font-12">
            {status}
          </Tag>
        );
      }
    }
  ];

  const [verifyAppCustomDomain, { loading }] = useMutation(
    VERIFY_APP_CUSTOM_DOMAIN,
    {
      onError() {}
    }
  );

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title="Domain records"
      width={850}
      okText="Verify"
      closable={false}
      onOk={() => {
        verifyAppCustomDomain({
          variables: {
            where: {
              id: appId
            }
          }
        }).then(() => {
          refetch({
            variables: {
              where: { id: appId }
            }
          });
          setOpenModal(false);
        });
      }}
      confirmLoading={loading}
    >
      <Table
        rowKey={(obj) => obj?.name}
        columns={columns}
        bordered={false}
        dataSource={data}
        pagination={false}
      />
    </Modal>
  );
};

export default DomainRecordsModal;
