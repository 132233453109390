import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table as AntdTable, Button, Tooltip } from 'antd';
import { ArrowsOutCardinal } from 'phosphor-react';
import React, { forwardRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { modalContext } from '../../../components/AppComponentContainer';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { DELETE_MENU, UPDATE_MENU_ORDER } from '../graphql/Mutations';

const MENUS_TYPES = {
  INTERNAL: 'Internal',
  EXTERNAL: 'External'
};
const POSITIONS = {
  HEADER: 'Header',
  FOOTER: 'Footer'
};

const Actions = ({ id, removeItem, isDeleteAllowed }) => {
  const history = useHistory();
  const [deleteMenu] = useMutation(DELETE_MENU);

  const handleEdit = () => {
    history.push(`${ROUTES?.MENUS}/${id}/edit`);
  };

  const handleDelete = () => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this menu?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        deleteMenu({
          variables: { id }
        })
          .then(() => {
            if (removeItem) {
              removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  return (
    <>
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Menu`}>
        <Button type="text" className="text-btn" onClick={handleEdit}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {isDeleteAllowed && (
        <Tooltip title="Delete Menu">
          <Button type="text" className="text-btn" onClick={handleDelete}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

const DragHandler = ({ handlers }) => (
  <div className="d-flex align-center justify-center">
    <ArrowsOutCardinal
      {...handlers}
      className="grab"
      style={{ outline: 'none' }}
      size={16}
    />
  </div>
);

const getColumns = (
  removeItem,
  showExpandableColumn,
  isAddEditAllowed,
  isDeleteAllowed
) => [
  isAddEditAllowed && {
    title: ' ',
    dataIndex: 'id',
    key: 'id',
    width: 30,
    render: (handlers) => <DragHandler handlers={handlers} />
  },
  ...(showExpandableColumn ? [AntdTable.EXPAND_COLUMN] : []),
  { title: 'Name', dataIndex: 'name', key: 'name' },
  {
    title: 'Destination',
    dataIndex: 'type',
    key: 'type',
    render: (value) => MENUS_TYPES[value] ?? '-'
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    render: (value) => POSITIONS[value] ?? '-'
  },
  {
    title: 'Page or URL',
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    render: (value) => (
      <Actions
        id={value}
        removeItem={removeItem}
        isDeleteAllowed={isDeleteAllowed}
      />
    )
  }
];

const TableRow = forwardRef(({ children, ...rest }, ref) => (
  <tr ref={ref} {...rest}>
    {children}
  </tr>
));

const Draggable = ({ id, children, className, style: defaultStyles }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging
  } = useSortable({
    id,
    strategy: verticalListSortingStrategy
  });

  const [firstChild, ...restChildren] = children;

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners
  };

  return (
    <TableRow
      className={className}
      style={{ ...style, ...defaultStyles }}
      ref={setNodeRef}
    >
      {React.cloneElement(firstChild, {
        render: firstChild.props.render.bind(null, handlers)
      })}
      {restChildren}
    </TableRow>
  );
};

const DraggableRow = ({ className, children, style, ...rest }) => {
  const id = rest['data-row-key'];
  if (!id)
    return (
      <TableRow className={className} style={style}>
        {children}
      </TableRow>
    );
  return (
    <Draggable id={id} className={className} style={style}>
      {children}
    </Draggable>
  );
};

const Table = ({
  loading,
  data = [],
  parentIds = [],
  removeItem,
  moveItem,
  onMoveSuccess,
  isAddEditAllowed,
  isDeleteAllowed
}) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const [updateMenuOrder] = useMutation(UPDATE_MENU_ORDER);
  const sensors = useSensors(mouseSensor, touchSensor);

  const hasChildren = useMemo(
    () => data.some((menu) => menu.menuItems?.length),
    [data]
  );

  const columns = useMemo(
    () =>
      getColumns(
        removeItem.bind(null, parentIds),
        hasChildren,
        isAddEditAllowed,
        isDeleteAllowed
      )?.filter((item) => item !== false),
    [removeItem, parentIds, hasChildren]
  );

  const handleDragEnd = ({ over, active }) => {
    if (!over) return;

    const source = active.id;
    const destination = over.id;

    if (source === destination) return;

    const sourceIndex = data.findIndex(({ id }) => id === source);
    const destinationIndex = data.findIndex(({ id }) => id === destination);

    const newItems = [...data];
    const item = newItems.splice(sourceIndex, 1);
    if (!item.length) return;
    newItems.splice(destinationIndex, 0, item[0]);

    updateMenuOrder({
      variables: {
        order: destinationIndex + 1,
        id: source
      }
    })
      .then(({ errors }) => {
        if (errors) throw new Error('Something wrong!');
        onMoveSuccess();
      })
      .catch(() => {
        moveItem(parentIds, [...data]);
      });

    moveItem(parentIds, newItems);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        strategy={verticalListSortingStrategy}
        items={data.map(({ id }) => id)}
      >
        <AntdTable
          loading={loading}
          rowKey="id"
          columns={columns}
          bordered={false}
          dataSource={data}
          pagination={false}
          components={{
            body: {
              row: isAddEditAllowed && DraggableRow
            }
          }}
          tableLayout="fixed"
          showHeader={!parentIds.length}
          {...(hasChildren && {
            expandable: {
              expandedRowRender: (record) => (
                <div className="nested-table--children">
                  <Table
                    removeItem={removeItem}
                    moveItem={moveItem}
                    onMoveSuccess={onMoveSuccess}
                    loading={loading}
                    data={record.menuItems}
                    parentIds={[...parentIds, record.id]}
                  />
                </div>
              ),
              columnTitle: ' ',
              columnWidth: 30,
              rowExpandable: (record) => record.menuItems?.length
            }
          })}
        />
      </SortableContext>
    </DndContext>
  );
};

export default Table;
