import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import { trim } from 'lodash';
import React, { useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GET_WORKSPACES_APPS } from '../../app/components/sidebar/graphql/Queries';
import {
  MAX_LENGTHS,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../videos/components/FormInputs';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';
import { GET_USER } from './graphql/Queries';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  contactInfo: {
    number: '',
    code: ''
  },
  username: '',
  permissions: []
};

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const PhoneInput = ({ value, onChange, placeholder, id }) => {
  const { number, code } = value;

  const handleChange = (val, { dialCode }) => {
    onChange({
      number: val?.replace(dialCode, ''),
      code: `+${dialCode}`
    });
  };

  return (
    <ReactPhoneInput
      inputProps={{ id }}
      placeholder={placeholder}
      value={`${code?.replace('+', '')}${number}`}
      onChange={handleChange}
    />
  );
};

const excludePermissions = ['LOGGED_IN', 'NOT_LOGGED_IN'];

const AddEditUser = ({ history, match: { params } }) => {
  const [form] = Form?.useForm();
  const { userId } = params;
  const isEdit = !!userId;

  const [getUser, { loading: fetchingDetails }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only'
  });

  const [addUpdateUser, { loading }] = useMutation(
    isEdit ? UPDATE_USER : CREATE_USER
  );

  useEffect(() => {
    if (isEdit && !!userId) {
      getUser({
        variables: {
          where: {
            id: userId
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          form?.setFieldsValue({
            firstName: data?.user?.firstName ?? '',
            lastName: data?.user?.lastName ?? '',
            email: data?.user?.email ?? '',
            contactInfo: {
              number: data?.user?.contactNumber ?? '',
              code: data?.user?.countryCode ?? ''
            },
            permissions:
              data?.user?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            username: data?.user?.username
          });
        }
      });
    }
  }, [isEdit, userId, form]);

  const handleSubmit = ({
    contactInfo,
    permissions,
    email,
    username,
    appId,
    ...restValues
  }) => {
    const payload = {
      ...restValues,
      ...(!isEdit && { email }),
      contactNumber: contactInfo.number,
      countryCode: contactInfo.code,
      ...(isEdit && {
        permissions: permissions?.map(({ value }) => value) ?? []
      }),
      roles: [ROLE_KEYS?.USER],
      username: trim(username),
      appId: appId?.value
    };

    addUpdateUser({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: userId
          }
        })
      }
    }).then(() => {
      history?.push(ROUTES?.USERS);
    });
  };

  const handleCancel = () => {
    history?.push(ROUTES?.USERS);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              required
              rules={[
                formValidatorRules?.required('Please enter first name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.NAME)]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              required
              rules={[
                formValidatorRules?.required('Please enter username!'),
                formValidatorRules?.username
              ]}
            >
              <Input placeholder="Enter username" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter email!'
                },
                formValidatorRules?.email,
                formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)
              ]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter email"
              />
            </Form.Item>
            {!isEdit && (
              <Form.Item
                label="App"
                name="appId"
                rules={[{ required: true, message: 'Please select app!' }]}
              >
                <Select
                  placeholder="Select app"
                  query={GET_WORKSPACES_APPS}
                  variablesSelector={variablesSelector}
                  dataSelector={(data) => {
                    return (
                      data?.workspaceApps?.workspaceApps?.map(
                        ({ id, name }) => ({
                          label: name,
                          value: id
                        })
                      ) ?? []
                    );
                  }}
                  keys={{
                    data: 'workspaceApps',
                    records: 'workspaceApps',
                    count: 'count'
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Phone Number"
              name="contactInfo"
              rules={[
                formValidatorRules?.maxLength(
                  MAX_LENGTHS.PHONE_NUMBER,
                  undefined,
                  (value) => value?.number?.length > MAX_LENGTHS.PHONE_NUMBER
                )
              ]}
            >
              <PhoneInput placeholder="Enter phone number" />
            </Form.Item>
            {isEdit && (
              <Permissions
                label="Attributes"
                placeholder="Select attributes"
                excludeOptions={excludePermissions}
              />
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditUser;
