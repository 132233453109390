import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import EditorPreview from '../../components/EditorPreview';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import {
  Permissions,
  SelectSources
} from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select, SelectContributor } from '../videos/components/FormInputs';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import { CREATE_ARTICLE, UPDATE_ARTICLE } from './graphql/Mutations';
import { GET_ARTICLE } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  tags: [],
  topics: [],
  sources: [],
  status: STATUS_TYPES.DRAFT,
  contentRating: '',
  authors: [],
  html: '',
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  metaHeader: '',
  metaFooter: '',
  permissions: []
};
const AddEditArticle = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { articleId } = params;
  const isEdit = !!articleId;

  const [fetchArticleDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_ARTICLE,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (isEdit && !!articleId) {
      fetchArticleDetails({
        variables: {
          where: {
            id: articleId
          }
        }
      }).then((res) => {
        const article = res?.data?.articleAdmin;
        if (article) {
          const slug = article?.slug?.startsWith('/')
            ? article?.slug
            : `/${article?.slug}`;
          form.setFieldsValue({
            title: article?.title ?? '',
            description: article?.description ?? '',
            slug,
            permissions:
              article?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              article?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.id
              })) ?? [],
            topics:
              article?.topics?.length > 0
                ? map(article?.topics, (item) => {
                    return {
                      value: item?.id,
                      label: item?.name
                    };
                  })
                : [],
            sources:
              article?.sources?.length > 0
                ? map(article?.sources, (item) => {
                    return {
                      id: item?.id,
                      url: item?.image?.url,
                      title: item?.name
                    };
                  })
                : [],
            status: article?.status ?? STATUS_TYPES.DRAFT,
            contentRating: article?.contentRating ?? '',
            html: article?.html ?? '',
            authors:
              article?.authors?.map((author) => ({
                id: author?.id ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
                imageURL: author?.image?.url ?? '',
                color: author?.primaryColor ?? ''
              })) ?? [],
            image: {
              id: article?.image?.id ?? '',
              url: article?.image?.url ?? ''
            },
            imageThumbnail: {
              id: article?.imageThumbnail?.id ?? '',
              url: article?.imageThumbnail?.url ?? ''
            },
            metaHeader: article?.metaHeader ?? '',
            metaFooter: article?.metaFooter ?? ''
          });
        }
      });
    }
  }, [isEdit, articleId, form, fetchArticleDetails]);

  const [addUpdateArticle, { loading }] = useMutation(
    isEdit ? UPDATE_ARTICLE : CREATE_ARTICLE
  );

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      authors:
        data?.authors?.map((author, index) => {
          return {
            contributorId: author?.id,
            order: index + 1,
            type: 'AUTHOR'
          };
        }) ?? [],
      tags:
        data?.tags?.map((item, index) => {
          return {
            tagId: item?.value,
            order: index + 1
          };
        }) || [],
      topics:
        data?.topics?.length > 0
          ? map(data?.topics, (item, index) => {
              return {
                topicId: item?.value,
                order: index + 1
              };
            })
          : [],
      sources:
        data?.sources?.length > 0
          ? map(data?.sources, (item, index) => {
              return {
                sourceId: item?.id,
                order: index + 1
              };
            })
          : [],
      status: data?.status,
      contentRating: data?.contentRating || '',
      html: data?.html ?? '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      permissions: data?.permissions?.map(({ value }) => value)
    };

    addUpdateArticle({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: articleId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES.ARTICLES_MODULE);
    });
  };

  const handleCancel = () => {
    history.replace(ROUTES.ARTICLES_MODULE);
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <div>
      <PageHeader menu={MODULES.CONTENT_UNITS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item
                  label="Authors"
                  name="authors"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (value?.length < 1) {
                          throw new Error('Please select at least one author!');
                        }
                      }
                    }
                  ]}
                >
                  <SelectContributor
                    displayText="Author"
                    disabled={isViewOnly}
                    multiple
                  />
                </Form.Item>
                <Form.Item name="tags" label="Tags">
                  <Select
                    mode="multiple"
                    placeholder="Select tags"
                    query={GET_TAGS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.tagsAdmin?.tags?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? []
                    }
                    keys={{
                      data: 'tagsAdmin',
                      records: 'tags',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="topics"
                  label="Topics"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length) {
                          throw new Error('Please select topic!');
                        }
                      }
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select topic"
                    query={GET_TOPICS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? 0
                    }
                    keys={{
                      data: 'topicsAdmin',
                      records: 'topics',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item name="sources" label="Sources">
                  <SelectSources />
                </Form.Item>
                <Form.Item label="Status" name="status">
                  <AntdSelect
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select status"
                  />
                </Form.Item>
                <Form.Item
                  name="contentRating"
                  label="Content Rating"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter content rating" />
                </Form.Item>
                <Form.Item
                  label="Image"
                  name="image"
                  extra="Recommended size  (2500 * 1242)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image Thumbnail"
                  name="imageThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>

                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>

                <Permissions />
              </Col>
              <Col md={24} lg={12}>
                <Form.Item className="full-html" label="Html" name="html">
                  <EditorPreview disabled={isViewOnly} resize={false} />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditArticle;
